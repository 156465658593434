import { State } from '@/types'

export const fdsnClient = (state: State) => {
  return state.fdsnClient
}

export const focusEvent = (state: State) => {
  return state.focusEvent
}

export const focusStation = (state: State) => {
  return state.focusStation
}

export const eventById = (state: State) => (eventid: string) => {
  return state.events.find(e => e.public_id === eventid)
}

export const station = (state: State) => (net: string, sta: string) => {
  return state.inventory[net] ? state.inventory[net][sta] : null
}

export const inventory = (state: State) => {
  return state.inventory
}

export const filteredEvents = (state: State) => {
  if (state.fmMode) {
    if (state.fmFilter === 'emsc') {
      return state.events.filter(x => x.public_id.indexOf('EMSC') === 0)
    } else if (state.fmFilter === 'oca') {
      return state.events.filter(x => {
        const po = x.origin[0]
        const lat = po.latitude.value
        const lon = po.longitude.value
        return x.public_id.indexOf('EMSC') !== 0 && lat >= 40.5 && lat <= 52 && lon >= -6 && lon <= 11
      })
    }
  } else {
    const discardedEventTypes = ['not reported', 'not existing']
    return state.events.filter(x => x.public_id.indexOf('EMSC') !== 0 && (x.type == null || discardedEventTypes.indexOf(x.type) < 0))
  }
}

export const filteredStations = (state: State) => {
  return state.stations
}

export const fmMode = (state: State) => state.fmMode
